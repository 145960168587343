import React from 'react';
import FrontPage from "../../components/Services/FrontPage/FrontPage";
import GetAQutoe from "../../components/Services/GetAQuote/GetAQutoe";

import { useMediaQuery } from 'react-responsive';
import Content from "../../components/Services/Content/Content";


const Services = () => {
    const isMobile=useMediaQuery({minWidth:767});
    return (
        <div>
            <FrontPage/>
            {isMobile&&<GetAQutoe/>}
            <Content/>
        </div>
    );
};

export default Services;
