export const data =[
    {
        img:require("../../../assets/services/Basement.jpeg"),
        name:"Basement",
        description: "If you’ve outgrown your current home, a finished basement can be similar to" +
            " a home addition without the additional cost. No longer just a “basement”, a lower level" +
            " renovation offers much-needed space for living, playing," +
            " storage or somewhere for Grandparents to move in!",
        fullDesc:"We can take your tired old “basement” and craft a vibrant & stunning “lower level”!  " +
            "Lower levels are often the most neglected part of a home, but can have the most potential " +
            "for adding more usable space for your family. Lower level renovations offer additional living space," +
            " storage space, or play space, and can add valuable square footage to your home." +
            "\n" +
            "\n" +
            "\n" +
            "\n" +
            "\n" +
            "We have tackled many lower level renovations to add kitchens, granny suites," +
            " recreational rooms and living / entertaining spaces. With our space planning and " +
            "design expertise, you won’t feel like a basement dweller – you’ll just notice the clean details and" +
            " vibrant, ample space to play, relax and live." +
            "\n" +
            "\n" +
            "\n" +
            "If you have outgrown your home, a lower level transformation can be an attractive option to make your home" +
            " work for you again without the hassle and expense of uprooting your family to relocate."
    },
    {
        img:require("../../../assets/services/Kitchen.jpeg"),
        name:"Kitchen",
        description: "Ever notice that no matter how you plan a party, people always gravitate to the kitchen? " +
            "The kitchen is the hub and heart of a home. From full kitchen renovations to a simple cabinet refacing," +
            " we ensure that your kitchen is the pulse of your home and the most beautiful!",

        fullDesc:"The kitchen is one of the most important spaces in any home. It’s where life happens. Eating, entertaining," +
            " celebrating, teaching – kitchens are where memories are made." +
            "\n" +
            "\n" +
            "As a family-owned business, Denvol Build understand the importance of having a well-planned kitchen to" +
            " foster memory-making and family moments. That’s why we take the utmost care when planning and" +
            " designing new kitchens to account for your unique style, family size and space needs." +
            "\n" +
            "\n" +
            "Whether you are taking on an entire kitchen remodel, or simply looking for a aesthetic facelift," +
            " we work with you collaboratively from concept to design to build to ensure that" +
            " we create a kitchen optimized for your family." +
            "\n" +
            "\n" +
            "We have so many kitchen renovations under our belt that we can contribute years of renovation" +
            " expertise to help make design decisions, and offer leading industry partners to tackle every" +
            " project requirement."
    },
    {
        img:require("../../../assets/services/Plumbing.jpeg"),
        name:"Plumbing",
        description: "When it comes to plumbing, certain repairs show up all the time. Things like leaky faucet repairs," +
            " main water line replacements, low water pressure repairs, or sediment build up in " +
            "your pipes are all common issues." +
            "Let us help you with your general plumbing repairs.",
        fullDesc:"You’ve probably noticed soon-to-be-blocked drains in your bathroom or sink but haven’t " +
            "taken any action yet. Cleaning drains is no easy task and going down the DIY route can " +
            "create more problems with a big bill waiting at the finish line. If you’re looking to find " +
            "experienced, licenced and reliable plumbers in London, Ontario who will get the job done right " +
            "the first time, look no further than Denvol Build.\n" +
            "\n" +
            "Take a minute to think about the things that go down your drains every single day:" +
            " Food particles when you clean the dishes, soap, hair and bath gel when you take a shower" +
            " and detergent when your washing machine does your laundry. If you don’t get your drains" +
            " cleaned out regularly by calling our bathroom plumbing service, the buildup of dirt and" +
            " debris can wreak havoc on your pipes. That’s where our expert plumbers come in to help!\n" +
            "\n" +
            "Our team of highly trained, licenced and bonded plumbers are well versed in all angles of plumbing," +
            " both in residential and commercial environments.\n" +
            "\n" +
            "From the smallest clogged drain to the largest plumbing renovation, Denvol Build will " +
            "be there for you with prompt, professional service. We serve London, Ontario and the surrounding area."
    },
    {
        img:require("../../../assets/services/Drywall.jpeg"),
        name:"Drywall",
        description: "If you damage your wall with a chair or moving furniture around the house, with our work" +
            " experience and a fast action by you part (calling us) we can repair you wall or ceiling look like new again." +
            "Sometimes an hour of work on your wall or ceiling is enough to make miracles in the look of your home" +
            "A fast, economic and reliable service it's what we can offer with our handyman service. For your peace mind our work is 100% guaranteed.",
        fullDesc:
            "As a London, ON professionals we can provide you with all types of Drywall " +
            "Repairs services like Drywall Hole Repair, Drywall Crack Repair, and Drywall Patch Repair to name " +
            "just a few. Our professional Drywall Repairs team is bonded, licensed and insured. " +
            "They carry liability insurance and worker's compensation coverage. Denvol Build will make" +
            " sure your belongings are protected and your family is safe as we complete your Drywall Repairs project." +
            " Let us do the work, there is no need to get your hands dirty!"
    },

    {
        img:require("../../../assets/services/Heating.jpeg"),
        name:"Heating",
        description: "Your home’s heating system is crucial during the winter season." +
            " Not only does it maintain your desired comfort levels but it also protects" +
            " you from the life threatening, freezing temperatures common in certain parts " +
            "of the country. To ensure the safety of you and your family, it is beneficial to" +
            " understand the type of heating system you have, safe operating procedures and the" +
            " necessary repairs to keep it functioning properly. ",
        fullDesc:"Heating your home uses more energy and costs more money than any other system in your home -- " +
            "typically making up about 42% of your utility bill." +
            "No matter what kind of heating system you have in your house, you can save money and increase your " +
            "comfort by properly maintaining and upgrading your equipment. But remember, an energy-efficient " +
            "furnace alone will not have as great an impact on your energy bills as using the whole-house approach." +
            " By combining proper equipment maintenance and upgrades with recommended insulation, air sealing, and" +
            " thermostat settings, you can save about 30% on your energy bill while reducing environmental emissions."
    },
    {
        img:require("../../../assets/services/Tiles.jpeg"),
        name:"Tiles",
        description: "“Laying tile” is easy, but laying tile and doing it properly is difficult. From that point of view, " +
            "it may make more sense to hire a professional flooring expert than to do it yourself." +
            "Fortunately, we are here to help with your tile installation in London, Ontario. " +
            "You can trust our expert installers to lay your tiles professionally, with incredible attention to detail.",
        fullDesc:"Kitchen Backsplash to Kitchen Floor.  Bathroom Floor, shower, or both.  Entranceways, basement floors, " +
            "or the whole house!  Whether it’s a small renovation project that you have in mind, or something a little more involved, " +
            "we’re eager to work with you. Denvol Build journeymen installers use tried and true techniques combined" +
            " with the latest in material technologies to " +
            "create a finished product that any property owner will both enjoy and be proud to show for years to come.\n" +
            "\n" +
            "Contact us to arrange a consultation and estimate for any of your tile or flooring projects.\n" +
            "\n"
    },

];
