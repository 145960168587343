import React from 'react';
import {useHistory} from 'react-router-dom';


import classes from "./NewTechWood.module.scss";
import Preview from "../../../../assets/services/PreviewWood.jpeg";

const NewTechWood = () => {
    let history = useHistory();
    const goTo = () =>{
        history.push("/services/NewTechWood");
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    return (
        <div className={classes.NewTechWood}>
            <img src={Preview} alt="New Tech Wood"/>
            <h2>Supplying & Installment</h2>
            <button className={classes.btn} onClick={goTo}>NewTechWood</button>
        </div>
    );
};

export default NewTechWood;
