import React from 'react';

const NotFound = () => {
    return (
        <h1 style={{textAlign:'center'}}>
            Not Found
        </h1>
    );
};

export default NotFound;
