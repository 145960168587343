import React from 'react';
import classes from "./ServiceItem.module.scss";
import {useHistory} from 'react-router-dom';

const ServiceItem = props => {
    let history=useHistory();

    const goTo=()=>{
        history.push("services/"+props.url);
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <div className={classes.ServiceItem}>
            <img src={props.img} alt={props.name}/>
            <h2>{props.name}</h2>
            <p>{props.description}</p>
            <button onClick={goTo}>View {props.name}s</button>
        </div>
    );
};

export default ServiceItem;
