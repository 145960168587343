import React from 'react';
import {data} from '../Content/data';
import {NewTechData} from '../Content/NewTechWood/NewTechData'
import classes from './Service.module.scss';
import GalleryHook from "../../UI/GalleryHook/GalleryHook";


const Service = ({match}) => {
    const {
        params:{serviceId}
    } = match;


    let serviceObject = null;
    data.forEach((obj)=>{
        if (obj.name===serviceId){
            serviceObject=obj;
        }
    });
    if (serviceId==="NewTechWood"){
        serviceObject=NewTechData;
    }
    if (serviceObject===null){
        serviceObject={};
        serviceObject.name="Not found";
        serviceObject.img=require("../../../assets/services/notFound.jpeg");
    }


    return (
        <div className={classes.Service}>
            <div className={classes.Header}>
                <img src={serviceObject.img} alt={serviceObject.name}/>
                <h1>{serviceObject.name}</h1>
            </div>
            <div className={classes.Content}>
                <p>{serviceObject.fullDesc}</p>
                <GalleryHook folder={"services/"} id={serviceId}/>
            </div>
        </div>
    );
};

export default Service;

