import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';

import './App.css';
import Layout from './Layout/Layout';
import Home from './containers/Home/Home';
import Services from './containers/Services/Services';
import Service from "./components/Services/Service/Service";
import NotFound from "./components/UI/NotFound/NotFound";
import Review from "./containers/Review/Review";

const App = () =>{
  return(
      <Layout>
        <Switch>
            <Route path={'/'} exact component={Home}/>
            <Route path={'/services'} exact component={Services}/>
            <Route path={'/services/:serviceId'} component={Service}/>
            <Route path={'/reviews/:reviewId'} component={Review}/>
            <Route component={NotFound}/>
        </Switch>
      </Layout>
  )
};

export default withRouter(App);

