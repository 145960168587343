import React from 'react';

import classes from "./Content.module.scss";
import {data} from "./data";
import ServiceItem from "./ServiceItem/ServiceItem";
import NewTechWood from "./NewTechWood/NewTechWood";

const Content = () => {
    return (
        <div className={classes.Content}>
            <p>
                Denvol will handle your projects right from concept, design to contract management, and closeout.
                We work with our customers through all of the stages of completion with a great deal of dedication that
                has resulted in trusted relationships.
            </p>
            <div className={classes.ContentDiv}>
                {
                    data.map((data,key)=>
                    <ServiceItem key={key} img={data.img} name={data.name}
                                 description={data.description} url={data.name}/>)
                }
            </div>
            <div className={classes.ContentWood}>
                <NewTechWood/>
            </div>
        </div>
    );
};

export default Content;
