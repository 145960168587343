import React, {useEffect,useState} from 'react';
import {database} from "../../fb";
import GalleryHook from "../../components/UI/GalleryHook/GalleryHook";
import './Review.scss';
import StarRating from "react-star-ratings";

const Review = ({match}) => {
    const [review,setReview] = useState({});

    const {
        params:{reviewId}
    } = match;

    useEffect(()=>{
        database.ref('Reviews/').on('value',snapshot=>{
            let reviewsToArray = [];
            snapshot.forEach(childSnapshot=>{
                reviewsToArray.push(childSnapshot.val());
            });
            setReview(reviewsToArray[reviewId]);
        });
    },[]);
    return (
        <div className={"Review"}>
            <div className={"UserData"}>
                <img src={review.picture?review.picture:require('../../assets/home/avatar.png')} alt="User's profile" id={"avatar"}/>
                <h3>{review.name}</h3>
                <StarRating
                rating={5}
                starRatedColor={'orange'}
                starHoverColor={'gold'}
                numberOfStars={review.rating}
                starDimension={"25px"}
                starSpacing={"2px"}/>
                <span>{review.currentDate}</span>
            </div>
            <div className={"ReviewData"}>
                <h1>{review.reviewTitle}</h1>

                <p>{review.review}</p>
            </div>
            {review.reviewPics&&(
                <>
                    <h2 className={"ReviewData"}>Customer Pictures:</h2>
                    <GalleryHook urls={review.reviewPics}/>
                </>
            )}
        </div>
    );
};

export default Review;
