import React,{useState} from 'react';
import Toolbar from '../components/Menu/Toolbar/Toolbar';
import SideDrawer from "../components/Menu/SideDrawer/SideDrawer";
import Footer from "../components/Home/Footer/Footer";
import "./Layout.scss";

const Layout = props => {
    const [showSideDrawer,setShowSideDrawer] = useState(false);

    const toggleSideDrawer =()=>{
      setShowSideDrawer(!showSideDrawer);
    };

    return (
       <div className={"Layout"}>
           <Toolbar clicked={toggleSideDrawer}/>
           <SideDrawer closed={toggleSideDrawer} open={showSideDrawer}/>
           <main style={{marginTop:'100px',overflow:'hidden',paddingBottom:'626px'}}>
               {props.children}
           </main>
           <Footer/>
       </div>
    );
};

export default Layout;
