import React, {useCallback, useEffect, useState} from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from "react-awesome-lightbox";
import Spinner from "../Spinner/Spinner";
import {storage} from "../../../fb";

const GalleryHook = (props) => {
    const [src,setSrc] = useState([]),
        [lightBoxSrc,setLightBoxSrc] = useState([]),
        [lightBoxOn,setLightBoxOn] = useState(false),
        [lightBoxIndex,setLightBoxIndex] = useState(1);



    useEffect(()=>{
        function getMeta(url) {
            return new Promise((resolve, reject) => {
                let img = new Image();
                img.onload = () => resolve(img);
                img.onerror = () => reject();
                img.src = url;
            });
        }
        //getting the array of urls in the firebase folder
        if (props.folder){
            const fetchImages = async () => {
                let result = await storage.ref().child(props.folder +""+ props.id).listAll();
                let urlPromises = result.items.map(imageRef=>imageRef.getDownloadURL());
                return Promise.all(urlPromises);
            };


            fetchImages().then( urls => {
                const list = urls.map(async (url)=>{
                    let img = await getMeta(url);
                    let imageWidth = 0,
                        imageHeight = 0;
                    if (img.width > img.height) {
                        imageWidth = 5;
                        imageHeight = 3;
                    } else if (img.width < img.height) {
                        imageWidth = 2;
                        imageHeight = 4;
                    } else {
                        imageWidth = 3;
                        imageHeight = 3;
                    }
                    return {
                        src:url,
                        width:imageWidth,
                        height:imageHeight
                    };
                });

                Promise.all(list).then(values=>{setSrc(values)})
            });
            //turning the urls into the object with src width and height and assigning it to the state

            fetchImages().then(response=>(setLightBoxSrc(response)));
        }else if (props.urls){
            setLightBoxSrc(props.urls);
            const list = props.urls.map(async(url)=>{
                let img = await getMeta(url);
                let imageWidth = 0,
                    imageHeight = 0;
                if (img.width > img.height) {
                    imageWidth = 5;
                    imageHeight = 3;
                } else if (img.width < img.height) {
                    imageWidth = 2;
                    imageHeight = 4;
                } else {
                    imageWidth = 3;
                    imageHeight = 3;
                }
                return {
                    src:url,
                    width:imageWidth,
                    height:imageHeight
                };
            });
            Promise.all(list).then(value => {setSrc(value)})
        }
    },[props]);

    const toggleLightBox = useCallback((event,{photo,index}) =>{
        setLightBoxIndex(index);
        setLightBoxOn(true);
        document.getElementsByClassName("Toolbar")[0].style.display='none';
    },[]);
    const closeLightBox=()=>{
        setLightBoxOn(false);
        document.getElementsByClassName("Toolbar")[0].style.display='block';
    };

    return (
        <div>
            {src.length>0?<Gallery photos={src} onClick={toggleLightBox} targetRowHeight={400}/>:<Spinner/>}
            {lightBoxOn&&<Lightbox images={lightBoxSrc} startIndex={lightBoxIndex} onClose={closeLightBox}/>}
        </div>
    );
};

export default GalleryHook;
