import React from 'react';
import "./ScrollDownArrow.scss";

const ScrollDownArrow = () => {

    const scrollDown = () =>{
        console.log(window.innerHeight-80);
        window.scrollBy({
            top:(75*window.innerHeight/100)+150,
            behavior:"smooth"
        })
    };
    return (
        <div id={'ScrollDownArrow'} onClick={scrollDown}>
            <img src={require("../../../assets/home/arrowDown.png")} alt="arrow"/>
        </div>
    );
};

export default ScrollDownArrow;
